var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title":
            `[${_vm.dictValue}]` +
            _vm.$t("cip.plat.sys.dict.title.indexConfigHeadTitle"),
        },
        on: {
          "head-add": _vm.getNew,
          "head-romve": _vm.handleDelete,
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          tableOptions: _vm.optionChild,
          tableData: _vm.dataChild,
          "table-loading": _vm.loadingChild,
          "data-total": _vm.dataTotal,
          page: _vm.pageChild,
          gridRowBtn: _vm.gridRowBtn,
        },
        on: {
          "gird-handle-select-click": _vm.selectionChange,
          "grid-edit": _vm.rowUpdate,
          "grid-romve": _vm.rowDel,
          "row-update": _vm.addUpdate,
          "tree-load": _vm.treeLoad,
          "grid-addChild": _vm.handleAdd,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }